<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" style="display: flex; flex-direction: row" routerLink="/">
            <img src="assets/img/logo-small.svg" alt="logo" width="35">
            <p class="logo-text">Hírlevél Ellenőrző</p>
        </a>
        <ng-container *ngIf="navigationEnabled">
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item"><span class="nav-link" (click)="onClick('start')">Mi ez?</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('choose-us')">Miért hasznos?</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('ellenorzes')">Ellenőrzés</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('contact-us')">Kapcsolat</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Árak</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('maillist')">Érdeklődöm</span></li>
                    <li class="nav-item" style="margin-right: 20px"><span class="nav-link" (click)="onClick('newsletter-text')">Szövegírás</span></li>
                    <div class="navbar-btn">
                        <a href="tel:{{ConfigurationService.Config.contactPhone}}">{{ConfigurationService.Config.contactPhone}}</a>
                    </div>   </ul>
                <ul class="navbar-nav ms-auto for-responsive">
                    <li class="nav-item"><span class="nav-link" (click)="onClick('start')">Mi ez?</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('choose-us')">Miért hasznos?</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('ellenorzes')">Ellenőrzés</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('contact-us')">Kapcsolat</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Árak</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('maillist')">Érdeklődöm</span></li>
                    <li class="nav-item" ><span class="nav-link" (click)="onClick('newsletter-text')">Szövegírás</span></li>
                    <li class="nav-item" >
                        <a href="tel:{{ConfigurationService.Config.contactPhone}}">{{ConfigurationService.Config.contactPhone}}</a>
                    </li>
                </ul>

            </div>

        </ng-container>
        <ng-container *ngIf="!navigationEnabled">
            <div class="navbar-btn">
                <a href="tel:{{ConfigurationService.Config.contactPhone}}">{{ConfigurationService.Config.contactPhone}}</a>
            </div>
        </ng-container>

    </div>
</nav>
