import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    classApplied = false;
    navigationEnabled = false;
    protected readonly ConfigurationService = ConfigurationService;

    constructor(private router: Router) {
    }

    public onClick(elementId: string): void {
        const anchorElement = document.getElementById(elementId);
        if (anchorElement) {
            const yOffset = -120; // Set the desired offset in pixels
            const yCoordinate = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: yCoordinate, behavior: 'smooth'});
        }
    }

    ngOnInit() {
        this.disableNavigationOnSpecificPages();
    }

    private disableNavigationOnSpecificPages() {
        this.router.events.subscribe({
            next: value => {
                if (window.location.href.includes("/bevetel")) {
                    this.navigationEnabled = false;
                    return;
                }
                this.navigationEnabled = true;
            }
        })
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}
