import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from "@angular/common";

@Component({
    selector: 'app-stories',
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller) {
    }

    ngOnInit(): void {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }
}
