<div id="services" class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Segítünk hogy hírlevelei mindig célba érjenek!</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                       <div class="small-image ">
                           <img src="assets/img/kepek/2541672_check_protection_security_shield_icon.png"  alt="notification icons">
                       </div>
                    <h3 class="pt-20">Egyszerű</h3>
                    <p>Használja továbbra is a jól megszokott és kézreálló megoldásokat a levelezés kezelésére.</p>
                    <p> Megoldásunk a háttérben fut és kompatibilis az összes, népszerű levelezőszoftverrel.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <div class="small-image">
                        <img src="assets/img/kepek/2541661_coding_markup_html_icon.png"  alt="notification icons">
                    </div>
                    <h3 class="pt-20">Automatikus</h3>
                    <p>Rendszerünk folyamatosan figyeli az email szolgáltatók beállításait. Biztos lehet abban,
                        hogy hírlevelei garantáltan kézbesülnek. Bármilyen hátrányos változtatást azonnal érzékelünk és beavatkozunk.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <div class="small-image">
                        <img src="assets/img/kepek/2541671_cog_earth_security_settings_shield_icon.png"  alt="notification icons">
                    </div>
                    <h3 class="pt-20">Biztonságos</h3>
                    <p>Tudjuk mekkora érték vállalkozása számára az email cím. Velünk biztonságban tudhatja ezt a márkaelemet is. Rendszerünk figyeli a feketelistákat is, így azonnal értesítjük ha email címe felkerül ezekre.
                        Kérés esetén a probléma elhárításában is segítséget nyújtunk. gyorsan reagálhat erre.</p>
                </div>
            </div>
        </div>
    </div>
</div>
