import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { StickyNavModule } from 'ng2-sticky-nav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import {BannerComponent} from "./components/components/banner/banner.component";
import {StoriesComponent} from "./components/components/stories/stories.component";
import {HaromOszlopComponent} from "./components/components/harom-oszlop/harom-oszlop.component";
import {CheckEmailComponent} from "./components/components/check-email/check-email.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ContactUsComponent} from "./components/components/contact-us/contact-us.component";
import {GtagModule} from "angular-gtag";
import {NgOptimizedImage} from "@angular/common";
import {TeglaComponent} from "./components/components/tegla/tegla.component";
import {KezdoOldalComponent} from "./components/components/kezdo-oldal.component";
import { LostRevenueComponent } from './components/components/lost-revenue-page/lost-revenue.component';
import { ContactLineComponent } from './components/components/contact-line/contact-line.component';
import { NewsletterTextComponent } from './components/components/newsletter-text/newsletter-text.component';
import {RouterLink} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {configFactory, ConfigurationService} from "./services/configuration.service";
import {MatSliderModule} from "@angular/material/slider";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { PricingComponent } from './components/components/pricing/pricing.component';
import { MaillistComponent } from './components/components/maillist/maillist.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireModule} from "@angular/fire/compat";

@NgModule({
    declarations: [
        AppComponent,
        BannerComponent,
        StoriesComponent,
        HaromOszlopComponent,
        CheckEmailComponent,
        ContactUsComponent,
        TeglaComponent,
        KezdoOldalComponent,
        NavbarComponent,
        FooterComponent,
        LostRevenueComponent,
        ContactLineComponent,
        NewsletterTextComponent,
        NotFoundComponent,
        PricingComponent,
        MaillistComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StickyNavModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxSmartModalModule.forRoot(),
        TabsModule,
        NgxScrollTopModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        HttpClientModule,
        NgOptimizedImage,
        GtagModule.forRoot({trackingId: 'G-JE67JY0DRJ', trackPageviews: true}),
        RouterLink,
        MatSliderModule,
        MatButtonModule,
        MatSelectModule,
        RouterLink,
        TabsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore())
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            multi: true,
            deps: [ConfigurationService],
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
