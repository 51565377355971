import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-harom-oszlop',
  templateUrl: './harom-oszlop.component.html',
  styleUrls: ['./harom-oszlop.component.scss']
})
export class HaromOszlopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
