<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h2>Vegyük fel a kapcsolatot</h2>
            <p>Segítünk felmérni teljes levelezőrendszerét! Írja le a kiválasszon csomag nevét, vagy jelezze és egyedi ajánlatot adunk a rendszeres ellenőrzésre!</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">

                <div class="row justify-content-center">
                    <div class="col-md-8 col-12">
                        <div class="card card-outline-primary mt-5">
                            <div class="card-body">
                                <p class="text">Érdekődik a {{domain}} iránt? Adja meg az adatait és hamarosan
                                    felvesszük önnel a kapcsolatot!</p>
                                <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
                                    <div class="mb-3">
                                        <label for="name" class="form-label text">Név</label>
                                        <input type="text" class="form-control" id="nameMessage" aria-describedby="name"
                                               formControlName="name">
                                        <div class="custom-warning form-error"
                                             *ngIf="contactForm.controls['name'].invalid  && contactForm.controls['name'].touched"
                                             type="warning">
                                            Kérem, adja meg a nevét!
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label text">Email *</label>
                                        <input type="email" class="form-control" id="emailMessage"
                                               aria-describedby="email"
                                               formControlName="email">
                                        <div class="custom-warning form-error"
                                             *ngIf="contactForm.controls['email'].invalid  && contactForm.controls['email'].touched"
                                             type="warning">
                                            Kérem, egy érvényes email címet adjon meg!
                                        </div>
                                    </div>
                                    <div class="mb-3" formArrayName="technicalContacts">
                                        <div
                                            *ngFor="let mailControl of contactForm.controls['technicalContacts'].controls; index as i; last as isLast;">
                                            <ng-container [formGroupName]="i">
                                                <div class="d-flex row flex-wrap align-items-end mb-2">
                                                    <div class="col-7 ">
                                                        <label for="{{i}}_technmail" class="form-label text ">Másolatot
                                                            kap {{i + 1}}. *</label>
                                                        <input type="email" class="form-control " id="{{i}}_technmail"
                                                               aria-describedby="email"
                                                               [formControl]="mailControl">
                                                        <div class="custom-warning form-error "
                                                             *ngIf="mailControl.invalid && mailControl.touched"
                                                             type="warning">
                                                            Kérem, egy érvényes email címet adjon meg!
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <button type="button"
                                                                class="btn btn-secondary justify-content-end d-flex align-items-center trash-icon-button-height"
                                                                (click)="removeFromTechnicalForm(i)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16" fill="currentColor" class="bi bi-trash "
                                                                 viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                <path fill-rule="evenodd"
                                                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                            </svg>
                                                        </button>
                                                    </div>

                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="mt-4">
                                            <button type="button" class="btn btn-secondary" id="addEmailButton"
                                                    (click)="addToTechnicalForm()"
                                                    [disabled]="contactForm.controls['technicalContacts'].length > 4"
                                            >Újabb címzett hozzáadása
                                            </button>
                                        </div>

                                        <label *ngIf="contactForm.controls['technicalContacts'].length > 4">Maximális
                                            extra email címzettek
                                            száma 5.</label>
                                    </div>
                                    <div class="mb-3">
                                        <label for="phone" class="form-label text">Telefonszám</label>
                                        <input type="phone" class="form-control" id="phoneMessage"
                                               aria-describedby="phone"
                                               formControlName="phone">
                                        <div class="custom-warning form-error"
                                             *ngIf="contactForm.controls['phone'].invalid  && contactForm.controls['phone'].touched"
                                             type="warning">
                                            Kérem, adjon meg egy telefonszámot!
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="message" class="form-label text">Üzenet</label>
                                        <textarea class="form-control" id="messageMessage" rows="3"
                                                  formControlName="message"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-secondary" id="sendMessageButton"
                                            [disabled]="contactForm.invalid || this.blockSend">
                                        <span>Küldés</span>
                                        <div class="spinner-border" role="status" *ngIf="isLoading">
                                            <span class="sr-only"></span>
                                        </div>
                                    </button>

                                    <div type="success" class="custom-success mt-2" *ngIf="submitted">
                                        <h2>
                                            Az üzenetét sikeresen rögzítettük, hamarosan keresni fogjuk!
                                        </h2>
                                    </div>

                                    <div type="success" class="custom-error mt-2" *ngIf="restError">
                                        <h2>
                                            Az üzenetet nem tudtuk rögzíteni.
                                        </h2>
                                        <p>Sajnáljuk! Valamilyen technikai probléma lépett fel.
                                            Kérjük, keressen meg minket közvetlenül az alábbi email címen:
                                        </p>
                                        <div>
                                            <a style="background: #e6d3d3; padding: 6px;"
                                               href="mailto:{{mailtoLink}}">{{ConfigurationService.Config.contactEmail}}</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Nincs bonyolult adminisztrációs felület</h3>
                            <p>Az ellenőrzéseket automatikusan végezzük, önnek a feliratkozás után egyéb teendője nem
                                lesz.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-layers mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Könnyedén használható</h3>
                            <p>Rendszerünk a háttérben, saját szervereink segítségével működik, így függetlenek vagyunk
                                az ön infrastruktúrájától.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-countdown mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Megbízható</h3>
                            <p>Rendszerünk naponta többször végez teljeskörű ellenőrzést az ön levelezési
                                beállításain.</p>
                        </div>
                    </div>
                    <hr/>
                    <div class="media d-flex">
                        <i class="flaticon-call mr-3 blue"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Keressen minket közvetlenül is akár</h3>
                            <p>
                                <a href="mailto:segitunk@hirlevelellenorzo.hu">segitunk@hirlevelellenorzo.hu</a>
                            </p>
                            <p>
                                <a href="tel:+36300742451">+36 30 074 2451</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>


