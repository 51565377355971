import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit,OnDestroy{
    private countdownSubscription: Subscription;

    countdown = 10;
    constructor(private router: Router) { }

    ngOnInit(): void {
        const countdownObservable = interval(1000);
        this.countdownSubscription = countdownObservable.subscribe(() => {
            this.countdown--;
            if (this.countdown === 0) {
                this.router.navigate(['/']);
                this.countdownSubscription.unsubscribe();
            }
        });
    }

    ngOnDestroy(): void {
        this.countdownSubscription.unsubscribe();
    }
}
