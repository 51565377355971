import { Component, OnInit } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-tegla',
  templateUrl: './tegla.component.html',
  styleUrls: ['./tegla.component.scss']
})
export class TeglaComponent implements OnInit {

    constructor(public ngxSmartModalService: NgxSmartModalService,
                private viewportScroller: ViewportScroller) {
    }

    ngOnInit(): void {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }
}
