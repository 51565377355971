<div class="container">
    <h1>404</h1>
    <p>Az keresett oldal nem található.</p>
    <div class="standard-btn">
        <a routerLink="/">Vissza a főoldalra</a>
    </div>
    <ng-container *ngIf="countdown < 8">
        Átirányítjuk a főoldalra {{countdown}} másodperc múlva...
    </ng-container>
</div>
