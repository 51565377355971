import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {KezdoOldalComponent} from "./components/components/kezdo-oldal.component";
import {LostRevenueComponent} from "./components/components/lost-revenue-page/lost-revenue.component";
import {NotFoundComponent} from "./components/common/not-found/not-found.component";

const routes: Routes = [
    {path: '', component: KezdoOldalComponent},
    {path: 'bevetel', component: LostRevenueComponent},
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
