import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {Router} from "@angular/router";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    navigationEnabled = false;

    constructor(private viewportScroller: ViewportScroller,
                private readonly router: Router) {}

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.disableNavigationOnSpecificPages();
    }

    private disableNavigationOnSpecificPages() {
        this.router.events.subscribe({
            next: value => {
                if (window.location.href.includes("/bevetel")) {
                    this.navigationEnabled = false;
                    return;
                }
                this.navigationEnabled = true;
            }
        })
    }

}
