import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {finalize} from "rxjs/operators";
import {Gtag} from "angular-gtag";
import { ApiCheckResponse, RestApiService } from 'src/app/services/rest-api.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ShareEmailService } from 'src/app/services/share-email.service';

@Component({
    selector: 'app-check-email',
    templateUrl: './check-email.component.html',
    styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent {
    @Input() domain: string = "";
    submitted = false;
    restError = true;
    result: ApiCheckResponse | undefined;
    weHaveProblems = false;
    weHaveOnlySoftWarnings = false;
    weHaveOnlySoftWarningsCount = 0;
    emailFieldValue = "";
    isLoading = false;
    ConfigurationService = ConfigurationService;
    errorText: SafeHtml = "";

    constructor(private readonly api: RestApiService,
                private readonly sanitizer: DomSanitizer,
                private readonly emailShareService: ShareEmailService,
                private readonly gtag: Gtag,
                public readonly config: ConfigurationService) {
        this.resetResult();
    }

    private resetResult() {
        this.result = {
            error: -1,
            info: -1,
            softwarning: -1,
            warning: -1,
        }
    }

    submitMail() {
        this.gtag.event('check-email-submit-clicked');
        this.isLoading = true;
        this.transferEmailToOtherForm();
        if (this.emailFieldValue != null && this.emailFieldValue != "")
            this.api.checkEmail(this.emailFieldValue)
                .pipe(
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe({
                    next: value => {
                        this.submitted = true;
                        this.restError = false;
                        this.result = value;
                        this.createInformationTextAboutDanger(value);
                        this.checkErrorType(value);
                        setTimeout(()=>{
                            this.submitted = false;
                        }, ConfigurationService.Config.blockSubmitInMilliseconds)
                    },
                    error: err => {
                        this.submitted = true;
                        this.restError = true;
                        console.error(err);
                    },
                })
    }

    private checkErrorType(value: ApiCheckResponse) {
        this.weHaveOnlySoftWarningsCount = value.softwarning;
        this.weHaveOnlySoftWarnings = value.softwarning && !value.error && !value.warning;
    }

    private createInformationTextAboutDanger(value: ApiCheckResponse) {
        if (value.error || value.softwarning || value.warning) {
            this.weHaveProblems = true;
            let text = "<p>Ellenőriztük az Ön email címét és a következőket találtuk:</p> <ul>"
            if(value.error){
                text+=`<li><b>${value.error} súlyos hiba</b></li>`
            }
            if(value.softwarning){
                text+=`<li>${value.softwarning} félreértelmezhető technikai beállítás</li>`
            }
            if(value.warning){
                text+=`<li>${value.warning} potenciás veszélyforrás</li>`
            }
            text+="</ul> <p><b>Engedje meg hogy rendbetegyük levelezését!</b> Küldön üzenetet, hogy minél hamarabb megoldjuk a problémákat!</p>" +
                "        <div class=\"d-grid d-sm-flex justify-content-center\">\n" +
                "          <a class=\"btn btn-primary btn-transition px-6 mb-2\" href=\"#contact-us\" data-aos=\"flip-right\" data-aos-duration=\"1000\"  data-aos-once=\"true\"> RAKJUK RENDBE AZONNAL! </a>\n" +
                "        </div>"

            this.errorText = this.sanitizer.bypassSecurityTrustHtml(text)
        } else {
            this.weHaveProblems = false;
        }
    }

    reset() {
        this.submitted = false;
        this.emailFieldValue = "";
        this.restError = false;
        this.weHaveProblems = false;
        this.resetResult();
    }

    transferEmailToOtherForm(){
        this.emailShareService.updateEmail(this.emailFieldValue);
    }
}
