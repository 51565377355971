import { Component, OnInit } from '@angular/core';
import {Gtag} from "angular-gtag";
import {ConfigurationService} from "../../services/configuration.service";

@Component({
    selector: 'app-kezdooldal',
    templateUrl: './kezdo-oldal.component.html',
    styleUrls: ['./kezdo-oldal.component.scss']
})
export class KezdoOldalComponent implements OnInit {
    domain: any;

    constructor(public readonly config: ConfigurationService,
                private readonly gtag: Gtag) {
        this.domain = ConfigurationService.Config.appName;
        gtag.pageview();
    }

    ngOnInit(): void {
    }

}
