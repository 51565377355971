import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, tap} from "rxjs/operators";

export interface IConfig {
    appName: string;
    blockSubmitInMilliseconds: number;
    contactEmail: string,
    contactPhone: string,
    api: {
        check: string,
        contact: string,
        roi: string,
    }
}

export const configFactory = (config: ConfigurationService) => () =>
    config.loadAppConfigObservable().toPromise();

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    private config = new BehaviorSubject<IConfig>({
        appName: "HirlevelEllenorzo",
        blockSubmitInMilliseconds: 5000,
        contactEmail: "-",
        contactPhone: "+36 30 074 2451",
        api: {
            check: "/api/check",
            contact: "/api/contact",
            roi: '/api/roi"'
        }
    });
    config$: Observable<IConfig> = this.config.asObservable();
    private static _config: IConfig;

    static get Config(): IConfig {
        return this._config;
    }

    private isLoaded = false;

    constructor(private readonly httpClient: HttpClient) {
    }

    public loadAppConfigObservable(): Observable<IConfig> {
        if (!this.isLoaded) {
            return this.httpClient.get<IConfig>("assets/config.json").pipe(
                tap((value) => {
                    this.isLoaded = true;
                    this.config.next(value as IConfig);
                    ConfigurationService._config = value as IConfig;
                }),
                catchError((err) => {
                    console.error(
                        "Configuration load FAILED, using fallback values instead."
                    );
                    ConfigurationService._config = this.config.getValue();
                    return of(ConfigurationService._config);
                })
            );
        } else {
            return of(ConfigurationService._config);
        }
    }
}
