import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {
    public onClick(elementId: string): void {
        const anchorElement = document.getElementById(elementId);
        if (anchorElement) {
            const yOffset = -120; // Set the desired offset in pixels
            const yCoordinate = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: yCoordinate, behavior: 'smooth'});
        }
    }
}
