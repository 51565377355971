<div class="standard-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-start">
                        <h2>A szöveg az, ami igazán számít</h2>
                    </div>
                    <p>
                        Nem mindegy, hogy milyen szöveggel találkozik az olvasó, amikor megnyit egy hírlevelet. A
                        marketing világában a jó szöveg aranyat ér - egy jól megírt hírlevél nemcsak felkelti az
                        érdeklődést, de kimutatható üzleti növekedést is hoz.
                    </p>
                    <p>
                        A statisztikák szerint az emberek <u><a href="https://www.nngroup.com/articles/how-little-do-users-read/">
                        80%-a csak a címet olvassa el, és csak 20% olvassa el a teljes
                        tartalmat</a></u>. Ez azt jelenti, hogy minden szó számít: egy rosszul megválasztott mondat vagy
                        kifejezés könnyen elüldözheti a potenciális ügyfeleket.
                    </p>
                    <p>
                        <b>Szövegíró szolgáltatásunk segítségével</b> megkönnyítjük számodra, hogy ne ezen hasaljanak el
                        üzleti leveleid!
                        Kollégáink kifejezetten hírlevél és üzleti levél szövegírásra specializálódtak, így profi módon
                        képesek összefoglalni a lényeget, és olyan szöveget alkotni, ami nem csak eléri a
                        címzettet, de meg is győzi őt.
                    </p>
                    <p>
                        Ha érdekli ez a szolgáltatásunk, kérjük, töltsd ki a kapcsolatfelvételi űrlapot, vagy keress
                        minket a megadott elérhetőségeink egyikén,
                        és mi hamarosan felvesszük Önnel a kapcsolatot a részletekkel kapcsolatban.
                    </p>
                    <div class="standard-btn">
                        <a href="#contact-us">ÉRDEKEL A SZÖVEGÍRÓI SZOLGÁLTATÁS</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/ghostwriter.png" alt="Szövegíró">
                </div>
            </div>
        </div>
    </div>
</div>
