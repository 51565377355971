import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShareEmailService {
  private emailOfUser = new BehaviorSubject<string | null>(null);
  public emailOfUserAsObservable = this.emailOfUser.asObservable();

  constructor() { }

  updateEmail(newVal: string | null){
    this.emailOfUser.next(newVal);
  }
}
