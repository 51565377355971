import {Component, Input} from '@angular/core';
import {ApiCheckResponse, RestApiService} from "../../../services/rest-api.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ShareEmailService} from "../../../services/share-email.service";
import {Gtag} from "angular-gtag";
import {finalize} from "rxjs/operators";
import { ConfigurationService } from 'src/app/services/configuration.service';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {from} from "rxjs";

@Component({
  selector: 'app-maillist',
  templateUrl: './maillist.component.html',
  styleUrls: ['./maillist.component.scss']
})
export class MaillistComponent {
    @Input() domain: string = "";
    submitted = false;
    restError = true;
    isLoading = false;
    emailFieldValue = "";
    ConfigurationService = ConfigurationService;

    constructor(private readonly api: RestApiService,
                private readonly sanitizer: DomSanitizer,
                private readonly gtag: Gtag,
                private store: AngularFirestore,
                public readonly config: ConfigurationService) {
        this.resetResult();
    }

    private resetResult() {
    }

    submitMail() {
        this.gtag.event('subscribe-to-firebase-mail-list');
        this.isLoading = true;
        if (this.emailFieldValue != null && this.emailFieldValue != ""){
            from(
                this.store.firestore.runTransaction(() => {
                    return this.store.collection("emails").add(this.getData())
                })
            )
                .pipe(
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe({
                    next: value => {
                        this.submitted = true;
                        this.restError = false;
                    },
                    error: err => {
                        this.submitted = true;
                        this.restError = true;
                    },
                })
        }
    }

    private getData() {
        return {
            email: this.emailFieldValue,
            timestamp: new Date().toISOString()
        };
    }
}
