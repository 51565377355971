<div id="pricing" class="pricing-section price-section-two pt-100">
    <div class="container">
        <div class="section-title">
            <span>Áraink</span>
            <h2>Testreszabott rendszeres védelem</h2>
            <p>Akár havi egy kávé árával garantálhatja a stabil levelezését</p>
        </div>
        <div class="pricing-tabs" style="margin-top: 40px">
            <ngx-tabset>
                <ngx-tab tabTitle="Havi Előfizetésű csomagjaink">
                    <div class="row d-flex justify-content-center">
                        <div style="width: unset!important; margin: 20px">
                            <p>Havi számlázás esetén</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card  business-class ">
                                <p>Belépő csomag</p>
                                <h5>69 490 Ft + Áfa egyszeri alkalom* után</h5>
                                <h3>1 490 Ft. + Áfa<span> /hó</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> <b>Havi 1</b> alap ellenőrzés</li>
                                        <li><i class="flaticon-checked"></i> Alap riport</li>
                                        <li><i class="flaticon-checked"></i> Webcím alapján elérhető információk
                                            ellenőrzése
                                        </li>
                                        <li class="inactive"><i>X</i> Spam listák figyelése</li>
                                        <li class="inactive"><i>X</i> Hírlevélre feliratkozó, beépített email fiók</li>
                                        <li class="inactive"><i>X</i> Oldal elérhetőség ellenőrzése</li>
                                        <li class="inactive"><i>X</i> Potenciális csalók figyelése</li>
                                        <li class="inactive"><i>X</i> Viselkedés analízis</li>
                                        <li class="inactive"><i>X</i> Fájdalommentes hibajavítás</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Keressen minket!</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card business-class ">
                                <p>Nagyratörő csomag</p>
                                <h5>69 490 Ft + Áfa egyszeri alkalom* után</h5>
                                <h3>5 490 Ft. + Áfa<span> /hó</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> <b>Havi 4</b> bővített ellenőrzés</li>
                                        <li><i class="flaticon-checked"></i> Bővített riport</li>
                                        <li><i class="flaticon-checked"></i> Webcím alapján elérhető információk
                                            ellenőrzése
                                        </li>
                                        <li><i class="flaticon-checked"></i> Spam listák figyelése</li>
                                        <li><i class="flaticon-checked"></i> Hírlevélre feliratkozó, beépített email
                                            fiók
                                        </li>
                                        <li class="inactive"><i>X</i> Oldal elérhetőség ellenőrzése</li>
                                        <li class="inactive"><i>X</i> Potenciális csalók figyelése</li>
                                        <li class="inactive"><i>X</i> Viselkedés analízis</li>
                                        <li class="inactive"><i>X</i> Fájdalommentes hibajavítás</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Keressen minket!</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card  business-class ">
                                <p>Elit csomag</p>
                                <h5>69 490 Ft + Áfa egyszeri alkalom* után</h5>
                                <h3>15 490 Ft. + Áfa<span> /hó</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> <b>Havi minimum 28</b> részletes ellenőrzés</li>
                                        <li><i class="flaticon-checked"></i> Részletes riport</li>
                                        <li><i class="flaticon-checked"></i> Webcím alapján elérhető információk
                                            ellenőrzése
                                        </li>
                                        <li><i class="flaticon-checked"></i> Spam listák figyelése</li>
                                        <li><i class="flaticon-checked"></i> Hírlevélre feliratkozó, beépített email
                                            fiók
                                        </li>
                                        <li><i class="flaticon-checked"></i> Oldal elérhetőség ellenőrzése</li>
                                        <li><i class="flaticon-checked"></i> Potenciális csalók figyelése</li>
                                        <li><i class="flaticon-checked"></i> Viselkedés analízis</li>
                                        <li><i class="flaticon-checked"> </i>Fájdalommentes hibajavítás</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Keressen minket!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Éves Előfizetésű csomagjaink" [active]="true">
                    <div class="row d-flex justify-content-center">
                        <div style="width: unset!important; margin: 20px">
                            <p>Éves, egy összegű számlázás esetén</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card  business-class ">
                                <p>Belépő csomag</p>
                                <h5>69 490 Ft + Áfa egyszeri alkalom* után</h5>
                                <h3 style="margin-bottom: unset!important;">1 290 Ft. + Áfa<span> /hó</span></h3>
                                <h5 class="discount">2 400 Ft kedvezmény</h5>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> <b>Havi 1</b> alap ellenőrzés</li>
                                        <li><i class="flaticon-checked"></i> Alap riport</li>
                                        <li><i class="flaticon-checked"></i> Webcím alapján elérhető információk
                                            ellenőrzése
                                        </li>
                                        <li class="inactive"><i>X</i> Spam listák figyelése</li>
                                        <li class="inactive"><i>X</i> Hírlevélre feliratkozó, beépített email fiók</li>
                                        <li class="inactive"><i>X</i> Oldal elérhetőség ellenőrzése</li>
                                        <li class="inactive"><i>X</i> Potenciális csalók figyelése</li>
                                        <li class="inactive"><i>X</i> Viselkedés analízis</li>
                                        <li class="inactive"><i>X</i> Fájdalommentes hibajavítás</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Keressen minket!</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card business-class ">
                                <p>Nagyratörő csomag</p>
                                <h5>69 490 Ft + Áfa egyszeri alkalom* után</h5>
                                <h3 style="margin-bottom: unset!important;">4 990 Ft. + Áfa<span> /hó</span></h3>
                                <h5 class="discount">6 000 Ft kedvezmény</h5>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> <b>Havi 4</b> bővített ellenőrzés</li>
                                        <li><i class="flaticon-checked"></i> Bővített riport</li>
                                        <li><i class="flaticon-checked"></i> Webcím alapján elérhető információk
                                            ellenőrzése
                                        </li>
                                        <li><i class="flaticon-checked"></i> Spam listák figyelése</li>
                                        <li><i class="flaticon-checked"></i> Hírlevélre feliratkozó, beépített email
                                            fiók
                                        </li>
                                        <li class="inactive"><i>X</i> Oldal elérhetőség ellenőrzése</li>
                                        <li class="inactive"><i>X</i> Potenciális csalók figyelése</li>
                                        <li class="inactive"><i>X</i> Viselkedés analízis</li>
                                        <li class="inactive"><i>X</i> Fájdalommentes hibajavítás</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Keressen minket!</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card business-class ">
                                <p>Elit csomag</p>
                                <h5>69 490 Ft + Áfa egyszeri alkalom* után</h5>
                                <h3 style="margin-bottom: unset!important;">13 490 Ft. + Áfa<span> /hó</span></h3>
                                <h5 class="discount">24 000 Ft kedvezmény</h5>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> <b>Havi minimum 28</b> részletes ellenőrzés</li>
                                        <li><i class="flaticon-checked"></i> Részletes riport</li>
                                        <li><i class="flaticon-checked"></i> Webcím alapján elérhető információk
                                            ellenőrzése
                                        </li>
                                        <li><i class="flaticon-checked"></i> Spam listák figyelése</li>
                                        <li><i class="flaticon-checked"></i> Hírlevélre feliratkozó, beépített email
                                            fiók
                                        </li>
                                        <li><i class="flaticon-checked"></i> Oldal elérhetőség ellenőrzése</li>
                                        <li><i class="flaticon-checked"></i> Potenciális csalók figyelése</li>
                                        <li><i class="flaticon-checked"></i> Viselkedés analízis</li>
                                        <li><i class="flaticon-checked"> </i>Fájdalommentes hibajavítás</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Keressen minket!</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
        <div class="section-title">
            <p>*Minden előfizetést egy teljes körű audittal kezdünk<p>
        </div>
        <div class="custom-pricing">
            <h2>Egyedi igénye van?</h2>
            <h3>Vegye fel a kapcsolatot velünk testreszabott, egyedi ajánlatainkért!</h3>
            <div class="standard-btn">
                <a (click)="onClick('contact-us')">Keressen minket!</a>
            </div>
        </div>
    </div>
</div>
