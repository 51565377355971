import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {ViewportScroller} from "@angular/common";

@Component({
	selector: 'app-sass-one-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

	constructor(public ngxSmartModalService: NgxSmartModalService,
                private viewportScroller: ViewportScroller) {
    }

	ngOnInit(): void {
	}

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }
}
