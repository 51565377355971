<div id="pricing" class="pricing-section price-section-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Szolgáltatásaink</h2>
        </div>
        <div class="pricing-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="Webcím alapján elérhető információk ellenőrzése">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Webcím alapján elérhető információk ellenőrzése</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Nyomon követjük a nyilvános domain-, és levelezésinformációk alakulását
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Időben értesítjük a levelezési protokollok változásairól
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Garantáltan minden alkalommal teljes körü ellenőrzést hajtunk végre
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Spam listák figyelése">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Spam listák figyelése</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Ellenőrizzük, hogy az általunk figyelt, spam-címeket tömörítő publikus
                                            listákon szerepel-e az ön webcíme
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Ha ilyet detektálunk, haladéktalanul értesítjük Önt, hiszen nem garantált,
                                            hogy levelei célba érnek
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Hírlevelére feliratkozó beépülő email cím">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Hírlevelére feliratkozó beépülő email cím
                                </h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Közelről figyeljük levelezését egy élő email cím segítségével
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Azonnal jelezzük amennyiben valamilyen hibát érzékeltünk
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Riport">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Riport</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Rendszeres (csomagjának megfelelő) riportot készítünk a kézbesítések
                                            állapotáról
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Ezt PDF formátumban küldjük el
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Részletes statisztikát küldünk a levelezéséről
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Oldal elérhetőség ellenőrzése">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Oldal elérhetőség ellenőrzése</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Levelezése mellett az Ön weblodalának életjelét és tanusítványát is
                                            megfigyeljük
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Ha változást érzékel rendszerünk, azonnal értesítjük
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Potenciális csalók figyelése a neten">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Potenciális csalók figyelése a neten</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Az internetet figyeljük az Ön azonosságát esetlegesen ellopni szándékozó,
                                            ártó szándékú oldalak után kutatva
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Különféle, az Ön nevében létrehozott adathalász oldalakra vadászunk
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Különös, az Önéhez hasonló webcímek regisztrálását ellenőrizzük
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Viselkedés Analízis">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Viselkedés Analízis</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            A levelezésével kapcsolatos internetes tevékenységekben történő változásokat
                                            figyeljük
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Amennyiben a szokványos viselkedéstől eltérő magatartást detektálunk (pl.
                                            egyik héten látszólag aránytalanul sok levelet generáltak az Ön nevében) azt
                                            jelezzük
                                        </li>

                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Fájdalommentes hibajavítás">
                    <div class="row">
                        <div class="col-lg-2 col-md-10">
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="price-card active-price business-class ">
                                <h3>Fájdalommentes hibajavítás</h3>
                                <div class="price-feature">
                                    <ul>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            A publikus internetes rekordok kezelését részlegesen átvesszük Öntől
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Így a problémák jelentős részét nem csak detektálni tudjuk, de azonnal
                                            javítjuk is Önne
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Vállaljuk ezeknek a speciális beállításoknak a kezelését Ön helyett
                                        </li>
                                        <li>
                                            <i class="flaticon-clipboard"></i>
                                            Így a leggyorsabb hibajavítást vállaljuk, amivel akár levélkampány alatt már
                                            javítani tudjuk a problémát
                                        </li>

                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a (click)="onClick('contact-us')">Kezdjük el!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>
