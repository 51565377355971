<div id="home" class="main-banner banner-style-two banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1><span class="darker-blue-text">Hírlevél</span> kézbesítés <span class="darker-blue-text">garancia,</span> tudja <span class="darker-blue-text">biztonságban</span> email kampányait <span class="darker-blue-text">velünk.</span></h1>
                    <p>Győződjön meg a hírlevelek sikeres kézbesítéséről, hiszen a kampány amiről nem értesülnek a címzettek jóformán nem is létezik.</p>
                    <div class="banner-btn">
                        <a (click)="onClick('contact-us')">Érdekel</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-image-two">
<!--        <img src="assets/img/kepek/istockphoto-1182692465-170667a.jpg" alt="laptop">-->
        <img src="assets/img/logo-big.svg" alt="email" width="500">
<!--        <img src="assets/img/email-checks.svg" alt="iphone">-->
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
</div>
