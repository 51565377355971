import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiCheckResponse, APIROIResponse, RestApiService} from "../../../services/rest-api.service";
import {ConfigurationService} from "../../../services/configuration.service";
import {Gtag} from "angular-gtag";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'app-lost-revenue',
    templateUrl: './lost-revenue.component.html',
    styleUrls: ['./lost-revenue.component.scss']
})
export class LostRevenueComponent {
    roiForm: FormGroup;
    result: APIROIResponse | undefined;
    protected readonly ConfigurationService = ConfigurationService;

    submitted: boolean;
    restError = true;
    isLoading = false;

    testunber = 495.025;

    constructor(private readonly fb: FormBuilder,
                private readonly api: RestApiService,
                private readonly gtag: Gtag,
                public readonly config: ConfigurationService) {
        this.resetResult();
        this.resetForm();

    }

    private resetResult() {
        this.result = undefined
    }

    private resetForm() {
        this.roiForm = this.fb.group({
            emailAddress: ['', [Validators.email, Validators.required]],
            averageMailCountPerMonth: [5000],
            conversationRate: [0.013],
            averageBasketValue: [10000],
            currency: 'HUF'
        });
    }

    submitCalculation() {
        if(this.roiForm.valid){
            this.gtag.event('roi-submit-clicked');
            this.isLoading = true;
            const values = this.roiForm.getRawValue();
            this.api.sendRoiCalculation(values.emailAddress, values.averageMailCountPerMonth, values.conversationRate, values.averageBasketValue)
                .pipe(
                    finalize(()=>{
                        this.isLoading = false;
                    })
                )
                .subscribe({
                    next: value => {
                        this.submitted = true;
                        this.restError = false;
                        this.result = value;
                        setTimeout(()=>{
                            this.submitted = false;
                        }, ConfigurationService.Config.blockSubmitInMilliseconds)
                    },
                    error: err => {
                        this.submitted = true;
                        this.restError = true;
                        console.error(err);
                    },
                })

        }

    }

    getMaxBasketVal(){
        const currency = this.roiForm?.controls?.currency?.value;
        switch(currency){
            case 'HUF':
                return 100000
            case '€':
            case '$':
            default:
                return 500
        }
    }

    getMinBasketVal(){
        const currency = this.roiForm?.controls?.currency?.value;
        switch(currency){
            case 'HUF':
                return 1000
            case '€':
            case '$':
            default:
                return 1
        }
    }

    getStepBasketVal(){
        const currency = this.roiForm?.controls?.currency?.value;
        switch(currency){
            case 'HUF':
                return 500
            case '€':
            case '$':
            default:
                return 5
        }
    }


    handleCurrencyChange($value) {
        this.resetBasketValue();
    }

    resetBasketValue(){
        const currency = this.roiForm?.controls?.currency?.value;
        switch(currency){
            case 'HUF':
                this.roiForm?.controls?.averageBasketValue?.setValue(500);
                return;
            case '€':
            case '$':
            default:
                this.roiForm?.controls?.averageBasketValue?.setValue(5)
        }
    }

}
