
<div class="counter-section">
    <div class="container-fluid service-section">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="row" style="display: flex; justify-content: space-around">

                    <div class="col-lg-6 col-md-12">
                        <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                            <i class="flaticon-email"></i>
                            <h3>Írjon kérdésével bátran</h3>
                            <h4>
                                <a href="mailto:segitunk@hirlevelellenorzo.hu">segitunk@hirlevelellenorzo.hu</a>
                            </h4>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                            <i class="flaticon-call"></i>
                            <h3>Hívjon minket bizalommal</h3>
                            <h4>
                                <a href="tel:+36300742451">+36 30 074 2451</a>
                            </h4>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
