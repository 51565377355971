<div class="newsletter mt-4">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Hírlevél feliratkozás</h3>
                        <p>Tegye meg az első lépést, hogy emailjei emailei és hírlevelei célba érjenek! Értesüljön akcióinkról és híreinkről első kézből.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Email címe" name="EMAIL" required
                               autocomplete="on"
                               #prmEmail="ngModel"
                               email
                               [(ngModel)]="emailFieldValue">
                        <button class="default-btn electronics-btn"
                                *ngIf="!submitted"
                                [disabled]="prmEmail.invalid || submitted"
                                (click)="submitMail()">Feliratkozás</button>
                    </form>
                </div>
            </div>

            <div *ngIf="prmEmail.errors && prmEmail.touched" class="alert alert-success ">
                Kérjük, adjon meg egy érvényes címet.
            </div>

            <div class="column justify-content-center  align-items-center" *ngIf="isLoading">
                <span style="margin-right: 15px">Egy pillanat törelmet kérünk, ... </span>
                <span style="vertical-align: sub" class="spinner-border" role="status">
            <span class="sr-only"></span>
          </span>
            </div>

            <div *ngIf="submitted && !restError" role="alert" id="emailSuccess">
                <div type="success" class="custom-success">
                   Köszönjük, email címét eltároltuk, hamarosan jelentkezünk!
                </div>
            </div>


            <div *ngIf="submitted && restError" class="alert alert-danger " role="alert" id="apiError">
                <div type="success" class="custom-error">
                    <h2>
                        Nem sikerült az adatokat feldolgozni.</h2>
                    <p>Kérjük vegye fel a kapcsolatot velünk, vagy írjon egy levelet a következő email címre:</p>
                    <div>
                        <a style="background: #e6d3d3; padding: 6px;"
                           href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
