<div class="newsletter mt-4">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Ellenőrizze hírlevele kézbesítését itt!</h3>
                        <p>Adja meg email címét, hogy kiderítse milyenek a levelezési beállításai. A megadott email
                            címet nem tároljuk, kizárólag az ellenőrzés elvégzésére használjuk fel.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Email címe" name="EMAIL" required
                               autocomplete="on"
                               #prmEmail="ngModel"
                               [(ngModel)]="emailFieldValue">
                        <button class="default-btn electronics-btn"
                                [disabled]="prmEmail.invalid || submitted"
                                (click)="submitMail()">Ellenőrzés Futtatása
                        </button>
                    </form>
                </div>
            </div>

            <div *ngIf="prmEmail.errors && prmEmail.touched" class="alert alert-success ">
                Kérlek, adj meg egy érvényes címet.
            </div>

            <div class="column justify-content-center  align-items-center" *ngIf="isLoading">
                <span style="margin-right: 15px">Egy pillanat törelmet kérünk, az email címe ellenőrzése folyamatban van... </span>
                <span style="vertical-align: sub" class="spinner-border" role="status">
            <span class="sr-only"></span>
          </span>
            </div>

            <div *ngIf="!weHaveProblems && !restError" role="alert" id="emailSuccess">
                <div type="success" class="custom-success">
                    <h2>
                        Mindent rendben találtunk és a Hírlevél Ellenőrzővel ez így is maradhat!
                    </h2>
                    <p>
                        Ellenőriztük az email beállításait és egyelőre minden megfelelően működik. <b>Viszont a
                        folyamatosan változó protokollok és beállítások miatt levelezése működése bármikor
                        megváltozhat!</b>
                    </p>
                    <p>
                        Vegye fel velünk a kapcsolatot, hogy napi szintű ellenőrzést végezhessünk levelezésén, így
                        azonnal értesíteni tudjuk, ha problémás változásokat találunk a beállításokban - ez az időtálló
                        levelezés titka.
                    </p>
                    <div class="d-grid d-sm-flex justify-content-center">
                        <a class="btn btn-primary btn-transition px-6 mb-2" href="#contact-us"
                           (click)="transferEmailToOtherForm()">IDŐTÁLLÓ LEVELEZÉST AKAROK!</a>
                    </div>
                </div>
            </div>

            <div *ngIf="weHaveProblems && weHaveOnlySoftWarnings" role="alert" id="onlyWarnings">
                <div type="success" class="custom-semi-success">
                    <h2>
                        Mindent rendben találtunk* és a Hírlevél Ellenőrzővel ez így is maradhat!
                    </h2>
                    <p>
                        *Ellenőriztük az email beállításait és egyelőre minden megfelelően működik. <b>Azonban ellenőrzésünk során {{weHaveOnlySoftWarningsCount}} esetben találtunk olyan beálltást, amelyről
                        nem tudtuk eldönteni egyértelműen, hogy problémát jelenthet-e önnek.</b>
                    </p>
                    <p>
                        A folyamatosan változó protokollok és beállítások miatt levelezése működése bármikor megváltozhat!
                    </p>
                    <p>
                        Vegye fel velünk a kapcsolatot, hogy napi szintű ellenőrzést végezhessünk levelezésén, így
                        azonnal értesíteni tudjuk, ha problémás változásokat találunk a beállításokban - ez az időtálló
                        levelezés titka.
                    </p>
                    <div class="d-grid d-sm-flex justify-content-center">
                        <a class="btn btn-primary btn-transition px-6 mb-2" href="#contact-us"
                           (click)="transferEmailToOtherForm()">IDŐTÁLLÓ LEVELEZÉST AKAROK!</a>
                    </div>
                </div>
            </div>

            <div *ngIf="weHaveProblems && !weHaveOnlySoftWarnings" class="alert alert-danger " role="alert"
                 id="emailError">
                <div type="success" class="custom-error text-left">
                    <h2>
                        Jó, hogy ezt megnéztük, valami nincs rendben!
                    </h2>
                    <p [innerHTML]="errorText"></p>
                </div>
            </div>

            <div *ngIf="submitted && restError" class="alert alert-danger " role="alert" id="apiError">
                <div type="success" class="custom-error">
                    <h2>
                        Nem sikerült az adatokat feldolgozni.</h2>
                    <p>Kérjük vegye fel a kapcsolatot velünk, vagy írjon egy levelet a következő email címre:</p>
                    <div>
                        <a style="background: #e6d3d3; padding: 6px;"
                           href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
                    </div>
                </div>
            </div>

            <div class="subscribe-shape">
                <!--                <img src="assets/img/shape/subscribe-shape.png" alt="shape">-->
                <!--                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">-->
                <!--                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">-->
            </div>
        </div>
    </div>
</div>
