<div id="home" class="main-banner banner-style-two banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1><span class="darker-blue-text">Mennyi</span> bevételtől <span class="darker-blue-text">esik el, ha a levelezési</span>
                        beállításai nem
                        pontosak? </h1>
                    <h2 style="color: white!important;">Kalulátorunk segítségével számolja ki, <b>mennyi kerül Önnek</b>
                        a jelenlegi email konfigurációja!</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-image-two">
        <!--        <img src="assets/img/kepek/istockphoto-1182692465-170667a.jpg" alt="laptop">-->
        <img src="assets/img/logo-big.svg" alt="email" width="500">
        <!--        <img src="assets/img/email-checks.svg" alt="iphone">-->
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
</div>


<div class="newsletter" style="margin: 20px">
    <div class="container">
        <div class="newsletter-area">
            <div style="display:flex; flex-direction: column; align-items: center">
                <div class="leader-text">
                    <h1>Email beállítások szerinti, átlagosan kieső bevétel kalkulátor</h1>
                    <p class="banner-text">Kalkulátorunk az Ön által megadott adatok alapján, élőben ellenőrzi a legfontosabb publikus
                        levelezési beállításait, és az alapján becslést tesz arra, mennyi potenciális vevőt és rajtuk
                        keresztül lehetséges forgalmat veszít el.</p>
                    <p>Ugyanazt az automatizmust futtatjuk, amire szolgáltatásként is előfizethet. <a href="/">Részletek itt!</a></p>
                </div>
                <div class="col-lg-10">
                    <form [formGroup]="roiForm"
                          style="border: 1px solid darkblue; background-color: white; padding: 50px">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>Adja meg levelező címét</mat-label>
                            <input matInput placeholder="hirlevel@pelda.hu"
                                   [disabled]="submitted"
                                   formControlName="emailAddress" required>
                            <!--                            <mat-hint>Ez lehet a hírlevélküldő címe, vagy az egyéni email címe is: a lényeg a domain (a @ utáni rész) számunkra</mat-hint>-->
                            <mat-error
                                *ngIf="roiForm.controls.emailAddress.hasError('email') && !roiForm.controls.emailAddress.hasError('required')">
                                Email cím hibás.
                            </mat-error>
                            <mat-error *ngIf="roiForm.controls.emailAddress.hasError('required')">
                                Email cím megadása kötelező.
                            </mat-error>
                        </mat-form-field>


                        <div style="display:flex; flex-direction: row; justify-content: space-between">
                            <label>Mennyi levelet küld ki egy hónapban?</label>
                            <label style="font-weight: bold">{{roiForm.controls.averageMailCountPerMonth.value|number}}
                                darab</label>
                        </div>
                        <mat-slider
                            style="width: 100%"
                            [max]="50000"
                            [min]="100"
                            [step]="100"
                            [discrete]="false"
                            [disabled]="submitted"
                            [showTickMarks]="false">
                            <input matSliderThumb formControlName="averageMailCountPerMonth" #slider>
                        </mat-slider>

                        <div style="display:flex; flex-direction: row; justify-content: space-between">
                            <label>Email-ek átlagos vásárlási konverziós rátája?</label>
                            <label style="font-weight: bold">{{roiForm.controls.conversationRate.value|percent :'0.1'}}</label>
                        </div>
                        <mat-slider
                            style="width: 100%"
                            [max]="0.05"
                            [min]="0.001"
                            [step]="0.001"
                            [discrete]="true"
                            [disabled]="submitted"
                            [showTickMarks]="true">
                            <input matSliderThumb formControlName="conversationRate" #slider>
                        </mat-slider>


                        <div style="display: flex; flex-direction: row; justify-content: space-between">
                            <div style="flex-grow: 1">
                                <div style="display:flex; flex-direction: row; justify-content: space-between">
                                    <label>Átlagos kosárértéke?</label>
                                    <label
                                        style="font-weight: bold">{{roiForm.controls.averageBasketValue.value|number}}
                                        {{roiForm.controls.currency.value}}</label>
                                </div>
                                <mat-slider
                                    style="width: 100%"
                                    [max]="getMaxBasketVal()"
                                    [min]="getMinBasketVal()"
                                    [step]="getStepBasketVal()"
                                    [discrete]="false"
                                    [disabled]="submitted"
                                    [showTickMarks]="false">
                                    <input matSliderThumb formControlName="averageBasketValue" #slider>
                                </mat-slider>
                            </div>
                            <div style="padding-left: 40px">
                                <mat-form-field style="width: 150px">
                                    <mat-label>Pénznem</mat-label>
                                    <mat-select formControlName="currency"
                                                [disabled]="submitted"
                                                (selectionChange)="handleCurrencyChange($event)">
                                        <mat-option value="HUF">HUF</mat-option>
                                        <mat-option value="€">€</mat-option>
                                        <mat-option value="$">$</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>


                        <div style="display:flex; flex-direction: row; justify-content: center">
                            <button style="width: 50%;height: 70px;font-size: 23px;" mat-raised-button color="primary"
                                    [disabled]="roiForm.invalid || submitted || isLoading"
                                    (click)="submitCalculation()">
                                <span>Kalkuláció futtatása!</span>
                                <div class="spinner-border custom-spinner" role="status" *ngIf="isLoading">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>

                        <div *ngIf="result && !restError && result.estimatedMonthlyLostIncome !== 0" id="apiSuccess"
                             class="response-box">
                            <div class="custom-warning">
                                <div style="display:flex; flex-direction: row; justify-content: center">
                                    <h2 class="lost-income">{{result.estimatedMonthlyLostIncome|number: '0.0-0'}} {{roiForm.controls.currency.value}}</h2>
                                </div>
                                <h3 style="    text-align: center;">Számításaink szerint, havonta Ön <br/><b>ENNYI
                                    BEVÉTELTŐL ESIK EL</b> <br/> (megközelítőleg) a hibás emailbeállításainak
                                    köszönhetően. </h3>
                                <p>Vegye fel a kapcsolatot velünk <b>még ma</b>, hogy ezt <b><u>minél hamarabb rendbe
                                    tudjuk tenni Önnek!</u></b></p>
                                <div class="contact">
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href=#contact-us>Kapcsolatfelvételi űrlap</a>
                                    </div>
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
                                    </div>
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href="tel:{{ConfigurationService.Config.contactPhone}}">{{ConfigurationService.Config.contactPhone}}</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="result && !restError && result.estimatedMonthlyLostIncome === 0"
                             id="apiSuccessNoFal" class="response-box">
                            <div class="custom-warning">
                                <div style="display:flex; flex-direction: row; justify-content: center">
                                    <h2 class="no-lost-income">Minden rendben!</h2>
                                </div>
                                <h3 style="    text-align: center;">Számításaink szerint önnek <b>rendben vannak a
                                    levelezési beállításai</b>, ezért jelenleg (valószínűleg) <b>nem esik el
                                    jelentős bevételtől.</b></h3>
                                <p>Azért, hogy <b>ez így is maradjon hosszútávon</b>, javasoljuk, hogy vegye fel a
                                    kapcsolatot velünk <b>még ma</b>!</p>
                                <div class="contact">
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href=#contact-us>Kapcsolatfelvételi űrlap</a>
                                    </div>
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
                                    </div>
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href="tel:{{ConfigurationService.Config.contactPhone}}">{{ConfigurationService.Config.contactPhone}}</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="submitted && restError" id="apiError" class="response-box">
                            <div class="custom-error">
                                <h2>
                                    Nem sikerült az adatokat feldolgozni.</h2>
                                <p>Kérjük vegye fel a kapcsolatot velünk, vagy írjon egy levelet a következő email címre
                                    az adatokkal, és később elküldjök önnek a kalkuláció eredményét.</p>
                                <div class="contact">
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
                                    </div>
                                    <div>
                                        <a style="background: #e6d3d3; padding: 6px;"
                                           href="tel:{{ConfigurationService.Config.contactPhone}}">{{ConfigurationService.Config.contactPhone}}</a>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </form>

                </div>


            </div>


            <div class="subscribe-shape">
                <!--                <img src="assets/img/shape/subscribe-shape.png" alt="shape">-->
                <!--                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">-->
                <!--                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">-->
            </div>
        </div>
    </div>
</div>
