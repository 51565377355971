import { Component } from '@angular/core';

@Component({
  selector: 'app-newsletter-text',
  templateUrl: './newsletter-text.component.html',
  styleUrls: ['./newsletter-text.component.scss']
})
export class NewsletterTextComponent {

}
