<div class="standard-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-start">
                        <h2>Nem minden hírlevél kézbesül</h2>
                    </div>
                    <p>Nem, nem olvasta félre. Sajnos a hírlevelek jó része soha nem kerül a címzettek szeme elé. A statisztikák azt mutatják, hogy az
                        elküldött üzleti levelek hatoda a spam mappában végzi vagy egyáltalán nem éri el a kiszemelt postafiókot <a href="https://www.validity.com/resource-center/2020-deliverability-benchmark/" target="_blank">
                            (Validity 2020 Email Deliverability Benchmark).</a>
                    </p>
                    <p>Ezek az elveszett hírlevelek meghíúsítják az előzetes pénzügyi-, és marketing várakozásokat és aláássák a szakmai kompetenciákat.
                    </p>
                    <div class="standard-btn">
                        <a (click)="onClick('contact-us')">BIZTOS KÉZBESÍTÉS ITT</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/kepek/1936915_communication_mail_origami_paper_plane_icon.png"  alt="origami paper plane">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="standard-section container ptb-100">
    <div class="row align-items-center">
        <div class="col-lg-5">
            <div class="standard-img">
                <img src="assets/img/kepek/4698570_email_inbox_letter_message_notifications_icon.png"  alt="open envelope">
            </div>
        </div>
        <div class="col-lg-7">
            <div class="standard-text">
                <div class="section-title text-start">
                    <h2>Rejtve maradt levelezési beállítások
                    </h2>
                </div>
                <p>Mindennek hátterében a folyamatosan változó email szolgáltatói beállítások állnak. A szolgáltatókra komoly nyomás nehezedik a felhasználók részéről, hogy biztonságos, mégis kényelmes levelezést nyújtsanak. Az elmúlt években elszaporodó csalásokra és visszaélésekre reagálva az email szolgáltatók automatikus szűrők és szigorú beállítások használatához fordultak.
                </p>
                <div class="standard-btn">
                    <a (click)="onClick('contact-us')">BIZTOS KÉZBESÍTÉS ITT</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="standard-section ptb-100 ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-start">
                        <h2>Elveszett levelek, elveszett ügyfelek</h2>
                    </div>
                    <p>Mivel a csalók hitelesnek próbálják beállítani üzeneteiket, az email szolgáltatói szűrői gyakran a jóhiszemű üzleti hírleveleket is spam mappába teszik - vagy egyenesen megakadályozzák a kézbesítésüket. Ez komoly következménnyel jár a vállalkozások kapcsolattartására,
                        hiszen az elmaradt levelek csökkentik a vásárlók bizalmát, rontják a kampányok eredményességét vagy egyenesen veszélyt jelentenek a vállalkozásra például egy elveszett ÁSZF módosítás értesítő esetén.
                    </p>
                    <div class="standard-btn">
                        <a (click)="onClick('contact-us')">BIZTOS KÉZBESÍTÉS ITT</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/kepek/2620530_check_employee_job_seeker_unemployee_icon.png"  alt="two users">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="standard-section container ptb-100 last-container-margin-bottom">
    <div class="row align-items-center">
        <div class="col-lg-5">
            <div class="standard-img">
                <img src="assets/img/kepek/2541664_changes_cog_info_preferences_settings_icon.png"  alt="open envelope">
            </div>
        </div>
        <div class="col-lg-7">
            <div class="standard-text">
                <div class="section-title text-start">
                    <h2>Beállítjuk, ellenőrizzük, riportálunk </h2>
                </div>
                <p> hírlevél beállítások teljes palettáját nyújtjuk. Munkatársaink ellenőrzik a megfelelő beállításokat és módosítják őket szükség esetén. Ezek után rendszerünk automatikusan figyeli és
                    folyamatosan ellenőrzi a beállításokat, bármilyen változás is áll be az email szolgáltató rendszerében, azonnal értesítést küldünk. Ezen felül havi rendszerességű riportokkal tájékoztatjuk a levelezéshez kapcsolódó beállításokról és statisztikákról.</p>
                <div class="standard-btn">
                    <a (click)="onClick('contact-us')">BIZTOS KÉZBESÍTÉS ITT</a>
                </div>
            </div>
        </div>
    </div>
</div>
