<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <div style="display: flex; flex-direction: row" routerLink="/">
                            <img src="assets/img/logo-small.svg" alt="logo" width="35">
                            <p class="logo-text" style="margin-bottom: 0!important">Hírlevél Ellenőrző</p>
                        </div>
                    </div>
                    <p>A célunk, hogy biztonságban tartsuk az ön üzleti levelezését és garantáljuk, hogy hírlevelei
                        mindig, minden körülmény között biztonságosan célba érjenek.</p>


                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div *ngIf="navigationEnabled" class="footer-widget pl-90">
                    <h3>Menü</h3>
                    <ul>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('start')">Mi ez?</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('choose-us')">Miért hasznos?</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('ellenorzes')">Ellenőrzés</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('contact-us')">Kapcsolat</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Árak</span></li>
                        <li class="nav-item" style="margin-right: 20px"><span class="nav-link" (click)="onClick('newsletter-text')">Szövegírás</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget d-flex flex-column align-items-center">
                    <a href="https://az-ev-webshopja.hu/"><img src="assets/img/conference/2023-Hivatalos-kiallitoja.png" width="120" alt="Az év webshopja verseny logója"/></a>
                    <p>Az <a href="https://az-ev-webshopja.hu/">Év Webshopja</a> verseny hivatalos partnere és kiállítója</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6" style="display: flex; flex-direction: row; justify-content: flex-end">
                <img src="assets/img/logo-big.svg" alt="logo" width="200">
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4 lh-1">
                    <p>© Hírlevél Ellenőrző 2023. Minden jog fenntartva. </p>
                </div>
                <div class="col-lg-4 custom-privacy-area">
                    <a href="assets/docs/felhasznalasi_feltetelek.pdf" target="_blank">Felhasználási feltételek</a> <span>-</span>
                    <a href="assets/docs/adatvedelmi_iranyelvek.pdf" target="_blank">Adatvédelmi irányelvek</a>
                </div>
                <div class="col-lg-4 col-md-8 lh-1">
                    <p style="font-size: 10px">A felhasznált képeket a Creative Commons <a href="https://creativecommons.org/licenses/by/3.0/">(Attribution
                        3.0 Unported) liszensz védi</a>, forrásuk az <a href="https://www.iconfinder.com/">iconfinder.com</a>.</p>
                </div>

            </div>
        </div>
    </div>
</footer>
