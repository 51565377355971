import {Component, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Gtag} from "angular-gtag";
import {finalize} from "rxjs/operators";
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { ShareEmailService } from 'src/app/services/share-email.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
    @Input() domain: string = "";
    submitted = false;
    restError = false;
    isLoading = false;
    blockSend = false;
    ConfigurationService = ConfigurationService;
    mailtoLink: string;

    contactForm = this.fb.group({
        name: [""],
        email: ["", [Validators.required, Validators.email]],
        technicalContacts: this.fb.array<string>([]),
        phone: [""],
        message: [""]
    })

    constructor(private readonly fb: FormBuilder,
                public readonly config: ConfigurationService,
                private readonly emailShareService: ShareEmailService,
                private readonly gtag: Gtag,
                private readonly api: RestApiService) {
        this.subscribeToEmailAddressInfo();
        this.mailtoLink = ConfigurationService.Config.contactEmail;
    }

    addToTechnicalForm(required = true) {
        if (required) {
            this.contactForm.controls.technicalContacts.push(new FormControl('', [Validators.email, Validators.required]))
        } else {
            this.contactForm.controls.technicalContacts.push(new FormControl('', [Validators.email]))
        }
        this.contactForm.controls.technicalContacts.updateValueAndValidity();
    }

    removeFromTechnicalForm(index: number) {
        this.contactForm.controls.technicalContacts.removeAt(index);
    }

    private subscribeToEmailAddressInfo() {
        this.emailShareService.emailOfUserAsObservable.subscribe({
            next: value => {
                this.contactForm.controls.email.patchValue(value)
            }
        })
    }

    updateMailtoLink() {
        this.mailtoLink = `${ConfigurationService.Config.contactEmail}?subject=Hirlevel%20Ellenőrző%20Kapcsolatfelvétel`;
        let body = "";

        const values = this.contactForm.getRawValue();
        if (values.name) {
            body += `Név: ${values.name} `;
        }
        if (values.email) {
            body += `%0D%0AEmail cím: ${values.email}`
        }
        if (values.phone) {
            body += `%0D%0ATelefonszám: ${values.phone}`
        }
        if (values.technicalContacts?.length > 0) {
            body += `%0D%0ATovábbi címzettek: ${values.technicalContacts.concat(",")}`
        }
        if (values.message) {
            body += `%0D%0AÜzenet:%0D%0A ${values.message}`
        }
        this.mailtoLink += `&body=${body}`
    }

    submitForm() {
        this.gtag.event("contact-us-form-submitted")
        this.updateMailtoLink();
        this.isLoading = true;
        if (this.contactForm.valid) {
            const values = this.contactForm.getRawValue();
            this.api.sendContactForm(values.name!, values.email!, values.phone!, values.message, values.technicalContacts as string[])
                .pipe(
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe({
                    next: value => {
                        this.blockSend = true;
                        setTimeout(() => {
                            this.blockSend = false;
                        }, ConfigurationService.Config.blockSubmitInMilliseconds)

                        this.submitted = true;
                        this.restError = false;
                        this.contactForm.reset();
                    },
                    error: err => {
                        this.blockSend = true;
                        this.submitted = false;
                        this.restError = true;
                    }
                })
        }
    }
}
